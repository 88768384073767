import { Observable } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LightboxDetailsDto } from '@cgib/shared/dtos/lightbox/lightbox-details.dto';
import { LightboxIdsDto } from '@cgib/shared/dtos/lightbox/lightbox-ids.dto';
import { LightboxItemInputDto } from '@cgib/shared/dtos/lightbox/lightboxItem.input.dto';
import { LightboxItemTypeEnum } from '@cgib/shared/enums/lightbox-type.enum';
import { MemoizedObservable } from '@cgib/shared/helper/memoized-observable';

interface LightboxProps {
    activatedLightboxId: string | undefined;
    domeIds: string[];
    imageIds: string[];
    locationIds: string[];
    sceneIds: string[];
    backplate3dIds: string[];
    partialLightboxes: Pick<LightboxDetailsDto, 'id' | 'name'>[];
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AsyncLightboxRepository {
    private readonly platformId = inject(PLATFORM_ID);
    lightboxStore = createStore(
        { name: 'LightboxRepository' },
        withProps<LightboxProps>({
            activatedLightboxId: undefined,
            domeIds: [],
            imageIds: [],
            locationIds: [],
            sceneIds: [],
            partialLightboxes: [],
            backplate3dIds: []
        })
    );
    constructor() {
        if (isPlatformBrowser(this.platformId)) {
            persistState(this.lightboxStore, {
                key: 'LightboxRepository',
                storage: localStorageStrategy
            });
        }
    }
    reset() {
        this.updateLightboxState({
            activatedLightboxId: undefined,
            domeIds: [],
            imageIds: [],
            locationIds: [],
            partialLightboxes: []
        });
    }
    getActiveLightboxId() {
        return this.lightboxStore.getValue().activatedLightboxId;
    }
    getPartialLightboxes() {
        return this.lightboxStore.getValue().partialLightboxes;
    }
    @MemoizedObservable()
    getActivatedLightboxId$(): Observable<LightboxProps['activatedLightboxId']> {
        return this.lightboxStore.pipe(select(state => state.activatedLightboxId));
    }

    @MemoizedObservable()
    getLightboxImageIds$(): Observable<LightboxProps['imageIds']> {
        return this.lightboxStore.pipe(select(state => state.imageIds));
    }

    @MemoizedObservable()
    getLightboxSceneIds$(): Observable<LightboxProps['sceneIds']> {
        return this.lightboxStore.pipe(select(state => state.sceneIds));
    }

    @MemoizedObservable()
    getLightbox3dBackplateIds$(): Observable<LightboxProps['backplate3dIds']> {
        return this.lightboxStore.pipe(select(state => state.backplate3dIds));
    }

    @MemoizedObservable()
    getLightboxDomeIds$(): Observable<LightboxProps['domeIds']> {
        return this.lightboxStore.pipe(select(state => state.domeIds));
    }

    @MemoizedObservable()
    getLightboxLocationIds$(): Observable<LightboxProps['locationIds']> {
        return this.lightboxStore.pipe(select(state => state.locationIds));
    }

    @MemoizedObservable()
    getPartialLightboxes$(): Observable<LightboxProps['partialLightboxes']> {
        return this.lightboxStore.pipe(select(state => state.partialLightboxes));
    }

    private updateLightboxState(props: Partial<LightboxProps>) {
        this.lightboxStore.update(state => ({ ...state, ...props }));
    }

    setActiveLightboxId(id: string | undefined) {
        this.updateLightboxState({ activatedLightboxId: id });
    }

    setPartialLightboxes(partialLightboxes: Pick<LightboxDetailsDto, 'id' | 'name'>[]) {
        this.updateLightboxState({ partialLightboxes });
    }

    // addItemsToLightbox(lightboxId: string, ids: LightboxItemDto[]) {
    //     const lightboxes = this.lightboxStore.getValue().lightboxes;
    //     lightboxes[lightboxId] = Array.from(new Set([...lightboxes[lightboxId], ...ids]));
    //     this.updateLightboxState({ lightboxes });
    // }

    // removeItemsFromLightbox(lightboxId: string, ids: LightboxItemDto[]) {
    //     const lightboxes = this.lightboxStore.getValue().lightboxes;
    //     lightboxes[lightboxId] = lightboxes[lightboxId].filter(
    //         item => !ids.some(itemToDel => itemToDel.id === item.id)
    //     );
    //     this.updateLightboxState({ lightboxes });
    // }

    updateActiveLightboxIds(ids: LightboxIdsDto) {
        this.updateLightboxState({
            domeIds: ids.domes,
            imageIds: ids.backplates,
            locationIds: ids.locations,
            sceneIds: ids.scenes,
            backplate3dIds: ids.backplate_3ds
        });
    }
    addItemsToActiveLightbox(ids: LightboxItemInputDto[]) {
        console.log(ids);
        this.lightboxStore.update(state => ({
            ...state,
            domeIds: Array.from(
                new Set([
                    ...state.domeIds,
                    ...ids.filter(item => item.type === LightboxItemTypeEnum.DOME).map(item => item.id)
                ])
            ),
            imageIds: Array.from(
                new Set([
                    ...state.imageIds,
                    ...ids.filter(item => item.type === LightboxItemTypeEnum.IMAGE).map(item => item.id)
                ])
            ),
            sceneIds: Array.from(
                new Set([
                    ...(state.sceneIds ?? []),
                    ...ids.filter(item => item.type === LightboxItemTypeEnum.SCENE).map(item => item.id)
                ])
            ),
            backplate3dIds: Array.from(
                new Set([
                    ...(state.backplate3dIds ?? []),
                    ...ids.filter(item => item.type === LightboxItemTypeEnum.BACKGROUND_3D).map(item => item.id)
                ])
            ),
            locationIds: Array.from(
                new Set([
                    ...state.locationIds,
                    ...ids.filter(item => item.type === LightboxItemTypeEnum.SUBJECT).map(item => item.id)
                ])
            )
        }));
    }

    removeItemsFromActiveLightbox(ids: string[]) {
        this.lightboxStore.update(state => ({
            ...state,
            domeIds: state.domeIds.filter(id => !ids.includes(id)),
            imageIds: state.imageIds.filter(id => !ids.includes(id)),
            locationIds: state.locationIds.filter(id => !ids.includes(id)),
            sceneIds: state.sceneIds?.filter(id => !ids.includes(id)) ?? [],
            backplate3dIds: state.backplate3dIds.filter(id => !ids.includes(id))
        }));
    }
}
